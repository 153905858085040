<template>
  <div>
    <Header>
      <template>消息通知</template>
      <template v-slot:right>
        <span style="color: #6ea4fd; font-size: 12px" @click="clickRight"
          >清除未读</span
        >
      </template>
    </Header>
    <!-- tab -->
    <div class="tab">
      <div
        class="tabItem"
        v-for="item in tagList.list"
        :key="item.id"
        @click="changeTab(item)"
        :class="item.id === tagList.active ? 'active' : null"
      >
        <span>
          {{ item.name }}
          <i v-if="item.id !== tagList.active && isshowNoRead"></i>
        </span>
      </div>
    </div>
    <!-- list -->
    <div class="list" ref="scroll">
      <div class="scroll">
        <div v-if="tagList.active === 0">
          <div class="listItem" v-for="item in noticeStatusList" :key="item.id">
            <i class="noRead" v-if="!item.isread"></i>
            <div class="time">{{ item.created_at }}</div>
            <div class="title">
              {{ item.title }}
            </div>
            <div class="subTitle">
              <span class="left">平台发布了新的公告</span>
              <span class="right" @click="updateStatus(item)">点击查看>></span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="listItem" v-for="item in orderStatusList" :key="item.id">
            <i class="noRead" v-if="!item.isread"></i>
            <div class="flex">
              <div class="title">
                {{ item.type === 1 ? '寄卖订单' : '购买商品' }}通知
              </div>
              <div class="time">{{ item.created_at }}</div>
            </div>
            <div class="subTitle">
              <span class="left">{{ item.message }}</span>
              <span class="right" @click="updateOrderStatus(item)"
                >点击查看>></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="def" v-if="isShowNoData">
        <img src="../../../static/image/hezi2.png" alt="" />
        <p>暂无内容</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageNotification',
  data() {
    return {
      tagList: {
        list: [
          { id: 0, name: '公告通知' },
          { id: 1, name: '订单通知' },
        ],
        active: 0,
      },
      noticeStatusQuery: {
        token: localStorage.getItem('token'),
        pageindex: 1,
        pagesize: 20,
      },
      orderStatusQuery: {
        token: localStorage.getItem('token'),
        pageindex: 1,
        pagesize: 20,
      },
      noticeStatusList: [],
      orderStatusList: [],
      noticeStatusNoticeRead: 0,
      orderStatusNoticeRead: 0,
      isNotOrderMore: false,
      isNotNoticeMore: false,
      noReadNum: 0,
    };
  },
  beforeDestroy() {
    if (this.$refs.scroll) {
      this.$refs.scroll.removeEventListener('scroll', this.onScroll);
    }
  },
  computed: {
    isshowNoRead() {
      if (this.tagList.active === 1 && this.noticeStatusNoticeRead !== 0) {
        return true;
      }
      if (this.tagList.active === 0 && this.orderStatusNoticeRead !== 0) {
        return true;
      }
      return false;
    },
    isShowNoData() {
      if (this.tagList.active === 0 && !this.noticeStatusList.length) {
        return true;
      }
      if (this.tagList.active === 1 && !this.orderStatusList.length) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.init();
    this.$refs.scroll.addEventListener('scroll', this.onScroll, true);
  },
  methods: {
    init() {
      this.getNoticeStatus();
      this.getOrderStatus();
      this.isNotOrderMore = false;
      this.isNotNoticeMore = false;
    },
    // 已读公告
    async updateStatus(item) {
      this.$router.push(`/Banner?id=${item.nid}`);
      if (item.isread) return;
      const res = await this.$api.updateStatus({
        id: item.nid,
        token: localStorage.getItem('token'),
      });
      if (res.code === 0) {
        this.noticeStatusList.forEach((e) => {
          if (e.id === item.id) {
            e.isread = 1;
            this.noticeStatusNoticeRead--;
          }
        });
      }
    },
    // 已读订单
    async updateOrderStatus(item) {
      if (item.type === 1) {
        this.$router.push(`/unpaid?id=${item.order_id}&orderType=1`);
      } else {
        this.$router.push(`/unpaid?id=${item.order_id}&orderType=2`);
      }
      if (item.isread) return;
      const res = await this.$api.updateOrderStatus({
        id: item.id,
        token: localStorage.getItem('token'),
      });
      if (res.code === 0) {
        this.orderStatusList.forEach((e) => {
          if (e.id === item.id) {
            e.isread = 1;
            this.orderStatusNoticeRead--;
          }
        });
      }
    },
    changeTab(item) {
      this.tagList.active = item.id;
      // this.noticeStatusQuery.pageindex = 1
      // this.orderStatusQuery.pageindex = 1
    },
    onScroll(e) {
      const clientHeight = e.target.clientHeight;
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (clientHeight + scrollTop >= scrollHeight) {
        console.log('到底了');
        if (this.tagList.active === 0) {
          this.noticeStatusQuery.pageindex++;
          this.getNoticeStatus(true);
        } else {
          this.orderStatusQuery.pageindex++;
          this.getOrderStatus(true);
        }
      }
    },
    // 全部已读
    async clickRight() {
      if (this.noReadNum == 0) return this.$toast('暂无未读消息');
      try {
        const res = await this.$api.clearAll({
          token: localStorage.getItem('token'),
        });
        if (res.code === 0) {
          this.noticeStatusQuery.pageindex = 1;
          this.orderStatusQuery.pageindex = 1;
          this.init();
          return this.$toast('成功');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getNoticeStatus(isMore) {
      if (isMore && this.isNotNoticeMore) return;
      try {
        const res = await this.$api.noticeStatus(this.noticeStatusQuery);
        if (res.data.length < this.noticeStatusQuery.pagesize) {
          this.isNotNoticeMore = true;
        }
        if (!isMore) {
          this.noticeStatusList = res.data;
        } else {
          this.noticeStatusList = this.noticeStatusList.concat(res.data);
        }
        this.noReadNum += res.notice_read;
        this.noticeStatusNoticeRead = res.notice_read;
      } catch (error) {
        console.log(error);
      }
    },
    async getOrderStatus(isMore) {
      if (isMore && this.isNotOrderMore) return;
      try {
        const res = await this.$api.orderStatus(this.orderStatusQuery);
        if (res.data.length < this.orderStatusQuery.pagesize) {
          this.isNotOrderMore = true;
        }
        if (!isMore) {
          this.orderStatusList = res.data;
        } else {
          this.orderStatusList = this.orderStatusList.concat(res.data);
        }
        this.noReadNum += res.notice_read;
        this.orderStatusNoticeRead = res.notice_read;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  background: #f8f8f8;
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 95px;
  overflow-y: auto;
  .scroll {
    .listItem {
      background: #fff;
      padding: 15px;
      border-radius: 5px;
      position: relative;
      margin-bottom: 20px;
      .noRead {
        display: block;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #e60012;
      }
      .flex {
        justify-content: space-between;
        align-items: center;
        display: flex;
        .title {
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .time {
        font-size: 12px;
        color: #999;
      }
      .title {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        margin: 12px 0 8px;
      }
      .subTitle {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        .left {
          display: inline-block;
          vertical-align: middle;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .right {
          display: inline-block;
          vertical-align: middle;
          color: #6ea4fd;
          margin-left: 8px;
        }
      }
    }
  }
}
.tab {
  display: flex;
  padding: 0 20px;
  height: 45px;
  .tabItem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #999;
    padding: 10px 0;
    border-bottom: 3px solid transparent;
    span {
      display: inline-block;
      position: relative;
      i {
        display: inline-block;
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #e60012;
        right: -2px;
        top: -2px;
      }
    }
    &.active {
      border-bottom: 3px solid #000000;
      color: #000000;
    }
  }
}
.def {
  text-align: center;
  margin-top: 20px;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
</style>
